import React, { useState } from 'react'
import Layout from '../components/layout'
import { Section, Heading } from '../styles/default-template.styled'
import { MainContainer } from '../styles/common.styled'
import { ContactContainer, HalfContainer, Label, Input, TextArea, ClearFix, Select, Submit, ContactText } from '../styles/contact.styled'
import map from '../images/mgmap.png'
import SEO from '../components/seo'
import swal from '@sweetalert/with-react'


const ContactPage = () => {


    const [formState, setFormState] = useState({
        name: "",
        email: "",
    })

    const seo = {
        description: "Kontakt oss for pristilbud eller andre henvendelser!"        
    }

    const submit = (e) => {
        if (formState.name === "" || formState.email ==="") {
            swal("Kunne ikke sende kontaktskjema", "Du må skrive inn navn og epostadresse", "warning");
            e.preventDefault()
        }
        
        
    }

    return (
        <Layout>
            <SEO title="Kontakt oss" seo={seo} />
            <Section>
                <MainContainer>
                    <Heading>Kontakt oss</Heading>
                    <ContactContainer>
                        <HalfContainer>
                            <ContactText>Murergutta as, Smidsrødveien 171b, 3140 Nøtterøy. Telefon <a href="tel:93697895">936 97 895</a></ContactText>
                            <form action="/takk" method="POST" name="contact" data-netlify="true">
                            <input type="hidden" name="subject" value="Melding fra kontaktskjema" />
                            <input type="hidden" name="form-name" value="contact" />                                
                                <Label>Ditt navn (obligatorisk)</Label>
                                <Input onChange={(e) => setFormState({...formState, name: e.target.value})} name="Navn" type="text"></Input>
                                <Label>Epost (obligatorisk)</Label>
                                <Input onChange={(e) => setFormState({...formState, email: e.target.value})} name="Epost" type="text"></Input>
                                <Label>Hva gjelder henvendelsen?</Label>
                                <Select name="Henvendelse">
                                    <option>Ønsker pristilbud</option>
                                    <option>Ring meg</option>
                                    <option>Annet</option>

                                </Select>

                                <Label>Melding</Label>
                                <TextArea name="Melding" placeholder="Skriv din melding her"></TextArea>

                                <Submit type="submit" value="Send" onClick={submit}/>
                            </form>
                        </HalfContainer>
                        <HalfContainer><img src={map} alt="kart"/></HalfContainer>
                        <ClearFix/>
                    </ContactContainer>
                </MainContainer>
            </Section>
        </Layout>
    )
}

export default ContactPage