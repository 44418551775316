import styled from 'styled-components'

export const ContactContainer = styled.div`
    margin-top: 75px;
    padding: 5px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;



    @media(max-width: 1200px) {
        margin-top: 35px;
    }

    @media(max-width: 768px) {
        flex-direction: column;     
        margin-top: 20px;
    }

    
`

export const ContactText = styled.p`
    margin-bottom: 35px;
`

export const HalfContainer = styled.div`
    width: 50%;
    padding-right: 25px;
    margin-bottom: 30px;
    font-family: Ubuntu, sans-serif;
    font-size: 16px;

    @media(max-width: 768px) {
        width: 100%;
        padding-right: 0;

    }


`

export const Label = styled.label`
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 400;
`

export const Input = styled.input`

    background: #fff;
    border: 1px solid #ccc;
    color: #444;
    box-shadow: none;
    border-radius: 0;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 3px 11px;
    height: 34px;
    line-height: 18px;
    -webkit-transition: border-color linear .1s;
    margin-bottom: 15px; 

`

export const Select = styled.select`
background: #fff;
border: 1px solid #ccc;
color: #444;
box-shadow: none;
border-radius: 0;
max-width: 100%;
width: 100%;
margin: 0;
padding: 3px 11px;
height: 34px;
line-height: 18px;
-webkit-transition: border-color linear .1s;
margin-bottom: 15px; 


`

export const TextArea = styled.textarea`
    width: 100%;
    height: 14rem;
    padding: 1rem;
`

export const ClearFix = styled.div`

clear: both;
    display: block;
    height: 0;
    font-size: 0;
    visibility: hidden;
    content: ".";
`

export const Submit = styled.input`
    cursor: pointer;
    margin: 0;
    color: #444;
    font-size: 16px;
    text-align: center;
    background: transparent;
    border: 4px solid #f39c12;
    border-color: #db002e;
    overflow: hidden;
    color: #db002e;

    position: relative;
    padding: 5px 22px;
    letter-spacing: 0;
    font-weight: 400;
    display: inline-block;
    text-transform: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all linear .1s;
    width: auto;
    box-sizing: border-box;
    -webkit-appearance: none;
    border-radius: 0;
    margin-top: 10px;
    padding: 10px 25px 10px 25px;

    &:hover {
        background-color:  #db002e;
        color: white;


    }
`